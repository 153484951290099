<template>
  <div class="Statistical">
    <div class="statistical-header">
      <div class="statistical-header-top">
        <module-title text="集团搜索"></module-title>
      </div>
      <div class="statistical-header-bottom">
        <div class="statistical-header-bottom-left">
          <a-form-model :colon="false">
            <a-form-model-item label="集团名称">
              <a-select
                show-search
                placeholder="请选择集团"
                :default-active-first-option="false"
                :filter-option="false"
                :not-found-content="null"
                style="width: 240px"
                notFoundContent="暂无数据"
                :value="params.groupId"
                class="hidden"
                :disabled="isShow"
                @search="handleSearch"
                @select="handleChange"
              >
                <a-select-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div
          class="statistical-header-bottom-right"
          @click="inquire"
          v-if="!isShow"
        >
          查询
        </div>
      </div>
    </div>
    <div class="statistical-footer">
      <div class="statistical-footer-left">
        <div class="best">
          <img src="../assets/image/best.png" alt="" />
          <div class="right">
            <div class="num">{{ bestNum }}</div>
            <div>90（含）分以上</div>
          </div>
        </div>
        <div class="good">
          <img src="../assets/image/good.png" alt="" />
          <div class="right">
            <div class="num">{{ goodNum }}</div>
            <div>80（含）-89分</div>
          </div>
        </div>
        <div class="bad">
          <img src="../assets/image/bad.png" alt="" />
          <div class="right">
            <div class="num">{{ badNum }}</div>
            <div>80分以下</div>
          </div>
        </div>
      </div>
      <div class="statistical-footer-right">
        <div class="title">
          <module-title text="回访报告评分占比"></module-title>
        </div>
        <div class="chart">
          <div ref="chart" style="width: 545px; height: 545px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { getGroup } from "../common/api/order";
import { getGroupData } from "../common/api/statistics";
import ModuleTitle from "../components/ModuleTitle";

export default {
  name: "Statistical",

  components: {
    ModuleTitle,
  },
  data() {
    return {
      isShow: false,
      groupParams: {
        page: 0,
        size: 9999,
        name: "",
      },
      params: {
        groupId: undefined,
      },
      bestNum: 0,
      goodNum: 0,
      badNum: 0,
      // 集团列表
      groupList: [],
      total: 0,
      // 饼图颜色 ["#d3d3d3","#2b56d9","#60b538","#dc3333"]
      color: ["#d3d3d3"],
      show: false,
      value: [{ value: 0, name: "暂无数据" }],
      mapData: {
        best: "90（含）分以上",
        good: "80（含）-89分",
        bad: "80以下",
        total: "总数",
      },
    };
  },

  methods: {
    async inquire() {
      if (!this.params.groupId) return this.$message.info("请选择集团");
      const res = await getGroupData(this.params);
      this.bestNum = res.data[this.mapData.best];
      this.goodNum = res.data[this.mapData.good];
      this.badNum = res.data[this.mapData.bad];
      this.total = res.data[this.mapData.total];
      if (!this.total) {
        this.$message.info("暂无数据");
        this.color = ["#d3d3d3"];
        this.value = [{ value: 0, name: "暂无数据" }];
        this.show = false;
      } else {
        const arr = [];
        for (let i = 0; i < 3; i++) {
          arr[i] = {};
          if (i == 0) {
            arr[0].value = this.bestNum;
            arr[0].name = this.mapData.best;
          } else if (i == 1) {
            arr[1].value = this.goodNum;
            arr[1].name = this.mapData.good;
          } else {
            arr[2].value = this.badNum;
            arr[2].name = this.mapData.bad;
            arr[2].name = arr[2].name.slice(0, 2) + "分" + arr[2].name.slice(2);
          }
        }
        this.color = ["#2b56d9", "#60b538", "#dc3333"];
        this.value = arr;
        this.show = true;
      }
      this.getEchartData();
    },
    handleSearch: debounce(function (e) {
      this.groupParams.name = e;
      this.getGroupList();
    }, 500),
    handleChange(e) {
      this.params.groupId = e;
    },
    // 获取门店集团
    async getGroupList() {
      const res = await getGroup(this.groupParams);

      this.groupList = res.data.content;
    },
    getEchartData() {
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          title: {
            zlevel: 0,
            text: ["{name|总份数}", "{value|" + this.total + "}"].join("\n"),
            rich: {
              name: {
                color: "#909399",
                lineHeight: 20,
              },
              value: {
                color: "#303133",
                fontSize: 40,
                fontWeight: "bold",
                lineHeight: 40,
              },
            },
            top: "center",
            left: "50%",
            textAlign: "center",
            textStyle: {
              rich: {
                name: {
                  color: "#909399",
                  lineHeight: 20,
                },
                value: {
                  color: "#303133",
                  fontSize: 40,
                  fontWeight: "bold",
                  lineHeight: 40,
                },
              },
            },
          },
          // 提示框
          tooltip: {
            show: this.show,
            trigger: "item",
          },
          // 标记
          legend: {
            // top: "0%",
            left: "center",
            itemWidth: 40,
            itemHeight: 20,
            textStyle: {
              fontSize: 14,
            },
          },
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              // 防止重叠
              avoidLabelOverlap: false,
              color: this.color,
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 2,
              },
              label: {
                show: false,
                position: "inside", //outside 外部显示  inside 内部显示
                formatter: `{d}%`,
                color: "#ffffff", //颜色
                fontSize: 12, //字体大小
              },
              emphasis: {
                label: {
                  show: this.show,
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: this.value,
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
  },

  async mounted() {
    await this.getGroupList();
    this.getEchartData();
    let roleId = window.sessionStorage.getItem("roleId");
    let gid = window.sessionStorage.getItem("gid");
    if (roleId == 5) {
      this.isShow = true;
      this.params.groupId = Number(gid);
      this.inquire();
    } else {
      this.isShow = false;
    }
  },
};
</script>

<style scoped lang="scss">
.statistical-header {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 0 30px 24px;
  .statistical-header-bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .statistical-header-bottom-left {
      margin-right: 79px;
      .ant-form {
        display: flex;
        flex-wrap: wrap;
        .ant-row::v-deep {
          margin-right: 32px;
          margin-bottom: 0;
          margin-top: 24px;
          display: flex;
          align-items: center;
          .ant-select-disabled .ant-select-selection {
            background: #fff;
            color: rgba(0, 0, 0, 0.65);
            .ant-select-arrow {
              display: none;
            }
          }
          &:nth-child(-n + 3) {
            margin-top: 0;
          }
          &:nth-child(3) {
            margin-right: 0;
          }
          .ant-input {
            width: 220px;
            height: 30px;
          }
          .ant-select {
            width: 220px;
            height: 30px;
            &.hidden::v-deep {
              .ant-select-arrow {
                display: none;
              }
            }
          }
          .ant-radio-group {
            display: flex;
          }
        }
      }
    }
    .statistical-header-bottom-right {
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      background: #83ba7b;
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.statistical-footer {
  margin-top: 33px;
  display: flex;
  .statistical-footer-left {
    .best,
    .good,
    .bad {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 34px;
      box-sizing: border-box;
      width: 529px;
      height: 223px;
      background: rgba(84, 112, 198, 0.25);
      border-radius: 16px;
      opacity: 1;
      img {
        width: 146px;
        height: 146px;
      }
      .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        font-size: 32px;
        color: #4f525a;
        .num {
          font-size: 78px;
          font-weight: 500;
          color: #2b56d9;
        }
      }
    }
    .good {
      margin: 32px 0;
      background: rgba(145, 204, 117, 0.25);
      .num {
        color: #60b538 !important;
      }
    }
    .bad {
      background: rgba(238, 102, 102, 0.25);
      .num {
        color: #dc3333 !important;
      }
    }
  }
  .statistical-footer-right {
    margin-left: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 24px 20px;
    .chart {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
