<template>
  <div class="Ques">
    <div class="ques-header">
      <div class="ques-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>报告搜索</div>
        </div>
        <div class="download" @click="assignDownload">下载所选</div>
      </div>
      <div class="ques-header-bottom">
        <div class="ques-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item label="客户/门店名称" v-if="isShow">
              <a-input
                v-model.trim="form.name"
                placeholder="请输入客户/门店名称"
                @pressEnter="find"
              />
            </a-form-model-item>
            <a-form-model-item label="集团品牌">
              <searchSelect
                :bid="form.bid"
                :iptWidth="180"
                @updateParams="groupChange"
              ></searchSelect>
            </a-form-model-item>
            <a-form-model-item label="提交人" v-if="isShow">
              <a-select v-model="form.uid" placeholder="请选择提交人">
                <a-select-option
                  v-for="(item, index) in allSaleList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="起止时间段">
              <a-date-picker
                v-model="form.starttime"
                :disabled-date="disabledStartDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请选择开始时间"
                @openChange="handleStartOpenChange"
              />
              <span> ── </span>
              <a-date-picker
                v-model="form.endtime"
                :disabled-date="disabledEndDate"
                :show-time="false"
                format="YYYY-MM-DD"
                placeholder="请选择结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="ques-header-bottom-right" @click="find">查询</div>
      </div>
    </div>
    <div class="ques-main">
      <a-table
        :columns="columns"
        :data-source="taskList"
        :loading="loading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :pagination="pagination"
      >
        <!-- 定位筛选 -->
        <span slot="customLoc">
          <a-select v-model="loc" @change="locChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in locList"
              :key="index"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <!-- 定位筛选 -->
        <span slot="customStatus">
          <a-select v-model="reviewStatus" @change="statusChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in statusList"
              :key="index"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <span slot="groupName" slot-scope="groupName">
          <span>{{ groupName ? groupName : "/" }}</span>
        </span>
        <span slot="finTime" slot-scope="finTime">
          <span>{{ finTime ? finTime.substr(0, 10) : "/" }}</span>
        </span>
        <span slot="isloc" slot-scope="isloc">
          <span :class="isloc ? 'have' : 'no'">{{ isloc ? "有" : "无" }}</span>
        </span>
        <span slot="status" slot-scope="status">
          <span>
            <span
              v-if="status == '已驳回' || status == '已确认'"
              :class="status == '已驳回' ? 'reject' : 'confirm'"
            >
              {{ status }}
            </span>
            <span v-else>{{ status }}</span>
          </span>
        </span>

        <span slot="action" slot-scope="item">
          <span class="download" @click="download(item)">下载</span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getUser } from "../common/api/user";
import { getTask } from "../common/api/ques";
import moment from "moment";
import { deepClone } from "../common/utils";
import http from "../common/http";
import SearchSelect from "../components/SearchSelect.vue";

const columns = [
  {
    title: "客户/门店名",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "集团名称",
    dataIndex: "groupName",
    key: "groupName",
    scopedSlots: { customRender: "groupName" },
    align: "center",
  },
  {
    title: "提交人",
    dataIndex: "username",
    key: "username",
    align: "center",
  },
  {
    title: "完成时间",
    key: "finTime",
    dataIndex: "finTime",
    scopedSlots: { customRender: "finTime" },
    align: "center",
  },
  {
    // title: "定位标记",
    key: "isloc",
    dataIndex: "isloc",
    slots: { title: "customLoc" },
    scopedSlots: { customRender: "isloc" },
    align: "center",
  },
  {
    // title: "复核标记",
    key: "reviewStatus",
    dataIndex: "reviewStatus",
    slots: { title: "customStatus" },
    scopedSlots: { customRender: "status" },
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  name: "Ques",

  components: {
    SearchSelect,
  },

  data() {
    return {
      isShow: true,
      loading: false,
      labelCol: { span: 9 },
      wrapperCol: { span: 20, offset: 1 },
      // 获取问卷列表请求参数
      form: {
        page: 0,
        size: 10,
        name: "",
        bid: undefined,
        uid: "",
        starttime: null,
        endtime: null,
        isloc: "",
        reviewStatus: 9,
      },
      endOpen: false,
      staffList: [],
      leaderList: [],
      allSaleList: [],
      // 问卷列表
      taskList: [],
      columns,
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      // 列表项配置对象
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) =>
          this.onRowChange(selectedRowKeys, selectedRows),
      },
      // 下载列表
      downloadList: [],
      // 定位选项
      locList: [
        {
          id: "",
          name: "不限",
        },

        {
          id: 1,
          name: "有",
        },
        {
          id: 2,
          name: "无",
        },
      ],
      // 状态选项
      statusList: [
        {
          id: 9,
          name: "不限",
        },
        {
          id: 2,
          name: "已确认",
        },
        {
          id: 3,
          name: "已驳回",
        },
      ],
      loc: "定位标记",
      reviewStatus: "复核标记",
    };
  },

  methods: {
    // 选择门店集团
    groupChange(e) {
      this.form.bid = e;
    },
    // 通过定位筛选问卷
    locChange(e) {
      console.log(e);
      if (e == "") {
        this.loc = "定位标记";
        this.form.isloc = "";
      } else {
        this.form.isloc = e;
      }
      this.pagination.current = 1;
      this.form.page = 0;
      this.getTaskList();
    },
    // 通过状态筛选问卷
    statusChange(e) {
      if (e == 9) {
        this.reviewStatus = "复核标记";
        this.form.reviewStatus = e;
      } else {
        this.form.reviewStatus = e;
      }
      this.pagination.current = 1;
      this.form.page = 0;
      this.getTaskList();
    },
    // 选中项发生变化时的回调
    onRowChange(selectedRowKeys, selectedRows) {
      // console.log(selectedRows);
      this.downloadList = selectedRows;
    },
    // 下载历史回访问卷
    download(item) {
      let fileName = item.name + item.finTime.substr(0, 10).replace(/-/g, "");
      console.log(fileName);
      const url = http.baseURL + "/" + item.reportPath;
      // let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getDay() {
      let time = new Date(),
        year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        timeStem = time.getTime();
      return `${year}/${month}/${day}/${timeStem}.pdf`;
    },
    // 查找
    find() {
      if (this.form.starttime && !this.form.endtime) {
        this.$message.info("请选择结束时间");
        return;
      } else if (!this.form.starttime && this.form.endtime) {
        this.$message.info("请选择开始时间");
        return;
      }
      this.form.page = 0;
      this.pagination.current = 1;
      console.log(this.form);
      this.getTaskList();
    },
    // 获取问卷列表
    async getTaskList() {
      let copyForm = deepClone(this.form);
      let copyStartTime;
      let copyEndTime;
      if (this.form.starttime) {
        copyStartTime = moment(this.form.starttime);
      }
      if (this.form.endtime) {
        copyEndTime = moment(this.form.endtime);
      }
      console.log(copyStartTime, copyEndTime, "1111");
      copyForm.page = copyForm.page.toString();
      console.log(copyForm, "copyForm.starttime");
      if (copyForm.starttime) {
        copyForm.starttime = copyStartTime.format("YYYY-MM-DD");
      } else {
        copyForm.starttime = "";
      }
      if (copyForm.endtime) {
        copyForm.endtime = copyEndTime.format("YYYY-MM-DD");
      } else {
        copyForm.endtime = "";
      }
      for (let k in copyForm) {
        if (!copyForm[k]) {
          delete copyForm[k];
        }
      }
      if (copyForm.isloc) {
        if (copyForm.isloc == "1") {
          copyForm.isloc = true;
        } else {
          copyForm.isloc = false;
        }
      }
      console.log(copyForm, "copyForm");
      this.loading = true;
      const res = await getTask(copyForm);
      this.loading = false;
      this.taskList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    disabledStartDate(startValue) {
      if (this.form.endtime) {
        return (
          startValue >
          moment(this.form.endtime, "YYYY-MM-DD")
            .subtract(-1, "days")
            .startOf("day")
        );
      }
    },
    disabledEndDate(endValue) {
      if (this.form.starttime) {
        console.log(endValue.format("YYYY-MM-DD"));
        return (
          endValue <
          moment(this.form.starttime, "YYYY-MM-DD")
            .subtract(1, "days")
            .endOf("day")
        );
      }
    },
    handleStartOpenChange(open) {
      if (!open && !this.form.endtime) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 批量下载
    assignDownload() {
      if (this.downloadList.length == 0) {
        return this.$message.info("请选择问卷");
      } else {
        this.downloadList.forEach((v) => {
          this.download(v);
        });
      }
    },

    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      this.leaderList = res.data.content;
      this.handleAllSale(this.staffList, this.leaderList);
    },
    // 获取业务员列表
    async getStaffList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 4,
      };
      const res = await getUser(params);
      // console.log("业务员列表:", res);
      this.staffList = res.data.content;
      this.getLeaderList();
    },
    // 获取所有业务员列表
    handleAllSale(a, b) {
      this.allSaleList = [...a, ...b];
      // 这里对id属性进行去重
      const map = new Map();
      this.allSaleList = this.allSaleList.filter(
        (item) => !map.has(item.id) && map.set(item.id, 1)
      );
      this.allSaleList.unshift({
        id: "",
        name: "不限",
      });
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getTaskList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getTaskList();
    },
    // 获取每个月的最后一天
    getMonthLastDate(month) {
      let dateObj = new Date(month);
      let nextMonth = dateObj.getMonth() + 1; //0-11，下一个月
      //设置当前日期为下个月的1号
      dateObj.setMonth(nextMonth);
      dateObj.setDate(1); //1-31
      let nextMonthFirstDayTime = dateObj.getTime(); //下个月一号对应毫秒
      let theMonthLastDayTime = nextMonthFirstDayTime - 24 * 60 * 60 * 1000; //下个月一号减去一天，正好是这个月最后一天
      let theMonthDay = new Date(theMonthLastDayTime).getDate();
      return month + "-" + theMonthDay;
    },
  },

  created() {
    this.$store.commit("updateIsKeepAlive", false);
    console.log(this.$route.query.params, "params");
    if (this.$route.query.params) {
      const params = JSON.parse(this.$route.query.params);
      this.form.name = params.name;
      let month = params.month;
      let startTime = month + "-01";
      this.form.starttime = moment(startTime);
      let endTime = this.getMonthLastDate(month);
      console.log("endTime", endTime);
      this.form.endtime = moment(endTime);
      this.form.uid = this.$route.query.params.saleId;
    }
    let roleId = window.sessionStorage.getItem("roleId");
    if (roleId == 5) {
      this.isShow = false;
    } else {
      this.isShow = true;
      this.getStaffList();
    }
    this.getTaskList();
  },
  activated() {
    let roleId = window.sessionStorage.getItem("roleId");
    if (roleId != 5) {
      console.log("执行了activated");
      this.$store.commit("updateIsKeepAlive", false);

      if (this.$route.query.params) {
        const params = JSON.parse(this.$route.query.params);
        this.form.name = params.name;
        let month = params.month;
        console.log("月份", month);
        let startTime = month + "-01";
        console.log("开始时间", startTime);
        this.form.starttime = moment(startTime);
        let endTime = this.getMonthLastDate(month);
        console.log("结束时间", endTime);
        this.form.endtime = moment(endTime);
        this.form.uid = this.$route.query.params.saleId;
      }
      this.getTaskList();
      this.getStaffList();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log("进入路由之前", to);
      console.log(from.name);
      let isKeepAlive = vm.$store.state.isKeepAlive;
      console.log(isKeepAlive, "isKeepAlive");
      console.log(from.name, "from.name");
      console.log(from.name == "Userdetails " && isKeepAlive);
      if (from.name == "Userdetails" && isKeepAlive) {
        to.meta.keepAlive = false;
      } else {
        to.meta.keepAlive = true;
      }

      console.log("进入路由之后", to);
    });
  },
};
</script>

<style scoped lang="scss">
.Ques {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .ques-header {
    .ques-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .download {
        margin: 0 16px 0 30px;
        width: 88px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
    .ques-header-bottom {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 24px 12px 0;
      .ques-header-bottom-left::v-deep {
        .ant-form {
          display: flex;
          align-items: center;

          .ant-row {
            margin-right: 25px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .ant-input,
            .ant-select {
              width: 150px;
            }
            .ant-calendar-picker:hover {
              .ant-input {
                border-color: #83ba7b !important;
              }
            }
          }
        }
      }
      .ques-header-bottom-right {
        margin-left: 120px;
        width: 72px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .ques-main {
    margin-top: 32px;
    .download {
      color: #3298f1;
      cursor: pointer;
    }
    .have {
      color: #9a9ea7;
    }
    .no {
      color: #e93024;
    }
    .reject {
      color: #e93024;
    }
    .confirm {
      color: #1eb040;
    }
  }
}
</style>
