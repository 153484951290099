<template>
  <div class="SearchSelect">
    <a-select
      show-search
      placeholder="请选择集团品牌"
      :default-active-first-option="false"
      :filter-option="false"
      :not-found-content="null"
      :style="`width:${iptWidth}px`"
      notFoundContent="暂无数据"
      :value="bid"
      class="hidden"
      @search="handleSearch"
      @select="handleChange"
    >
      <a-select-option
        v-for="(item, index) in groupList"
        :key="index"
        :value="item.id"
        >{{ item.groupBrand }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getGroup } from "../common/api/order";
import { debounce } from "lodash";
export default {
  name: "SearchSelect",
  props: {
    bid: {
      type: Number,
      default: undefined,
    },
    iptWidth: {
      type: Number,
      default: 240,
    },
    flag: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      groupList: [],
      params: {
        name: "",
        page: 0,
        size: 999,
      },
    };
  },
  methods: {
    // 获取门店集团
    async getGroupList() {
      const res = await getGroup(this.params);
      console.log(res, "集团品牌");

      let newArr = [];
      let obj = {};

      res.data.content.forEach((v) => {
        v.brandsSet.forEach((i) => {
          obj = { groupName: v.name, groupId: v.id, ...v, ...i };
          obj.groupBrand = obj.groupName + " ─ " + obj.name;
          newArr.push(obj);
        });
      });

      this.groupList = newArr;

      if (this.flag && !this.params.name && this.groupList.length != 0) {
        this.groupList.unshift({
          groupBrand: "不限",
          id: 0,
        });
      }
    },
    handleSearch: debounce(function (e) {
      this.params.name = e;
      this.getGroupList();
    }, 500),
    handleChange(e) {
      console.log(e, "eeeeeeeeeee");
      this.$emit("updateParams", e);
    },
  },
  created() {
    this.getGroupList();
  },
};
</script>

<style scoped lang="scss">
.hidden::v-deep {
  .ant-select-arrow {
    display: none;
  }
}
</style>
