<template>
  <div class="Login">
    <div :class="['login_box', isVisitor ? 'visitor' : '']">
      <div class="login_box_left">
        <div class="login_box_left_text">{{ title }}</div>
        <div class="login_box_form">
          <a-form-model
            layout="vertical"
            ref="ruleForm"
            :model="formData"
            :rules="rules"
          >
            <a-form-model-item prop="username" ref="name" :autoLink="false">
              <div class="area"></div>
              <a-input
                v-model="formData.username"
                placeholder="请输入用户名"
                allowClear
                :maxLength="20"
                @pressEnter="login"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                @change="
                  () => {
                    $refs.name.onFieldChange();
                  }
                "
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password" ref="password" :autoLink="false">
              <div class="area"></div>
              <a-input
                v-model="formData.password"
                type="password"
                placeholder="请输入密码"
                :maxLength="16"
                allowClear
                @pressEnter="login"
                @blur="
                  () => {
                    $refs.password.onFieldBlur();
                  }
                "
                @change="
                  () => {
                    $refs.password.onFieldChange();
                  }
                "
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item class="btn-item">
              <a-button
                @click="login"
                :disabled="formData.username === '' || formData.password === ''"
              >
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="login_box_alert">无法登录请联系 021-55854546</div>
      </div>
      <div :class="['login_box_right', isVisitor ? 'mr' : '']">
        <img :src="isVisitor ? visitor : staff" alt="" />
      </div>
    </div>
    <div class="login_mode" @click="modeChange">
      {{ isVisitor ? "员工登录 >>" : "访客登录 >>" }}
    </div>
  </div>
</template>

<script>
import { login } from "../common/api/login";
import staff from "../assets/image/staff.png";
import visitor from "../assets/image/visitor.png";
export default {
  name: "Login",

  data() {
    return {
      staff,
      visitor,
      title: "柯灵展新售后服务系统管理登录端",
      isVisitor: false,
      // 表单数据
      formData: {
        username: "",
        password: "",
      },
      // 表单验证规则
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    modeChange() {
      this.$refs.ruleForm.clearValidate();
      this.formData.username = "";
      this.formData.password = "";
      this.isVisitor = !this.isVisitor;
    },
    // 登录
    login(e) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          console.log(this.formData);
          const res = await login(this.formData);
          console.log("响应数据:", res);
          if (res.data.roleSet[0].id === 5 && !this.isVisitor) {
            this.$message.info("请选择访客登录");
            return;
          } else if (res.data.roleSet[0].id !== 5 && this.isVisitor) {
            this.$message.info("请选择员工登录");
            return;
          }
          const result = res.data.roleSet.some((item) => {
            return item.id == 1 || item.id == 3;
          });
          if (result) {
            window.sessionStorage.setItem(
              "sign",
              window.btoa("a%q&22podll.1vz")
            );
            this.$store.commit(
              "updatePermission",
              window.btoa("a%q&22podll.1vz")
            );
          } else {
            window.sessionStorage.setItem("sign", window.btoa("2"));
            this.$store.commit("updatePermission", window.btoa("2"));
          }

          console.log(result, "result");
          this.$message.success(res.msg);
          window.sessionStorage.setItem("name", res.data.name);
          window.sessionStorage.setItem("avatarPath", res.data.avatarPath);
          window.sessionStorage.setItem("roleId", res.data.roleSet[0].id);
          window.sessionStorage.setItem("gid", res.data.gid);
          window.sessionStorage.setItem("token", res.data.token);
          window.sessionStorage.setItem("refreshToken", res.data.refreshToken);
          window.sessionStorage.setItem("id", res.data.id);
          this.$router.push("/home");
        }
      });
    },
  },
  watch: {
    isVisitor(newVal) {
      if (newVal) {
        this.title = "柯灵展新售后服务系统访客登录端";
      } else {
        this.title = "柯灵展新售后服务系统管理登录端";
      }
    },
  },
  created() {
    window.sessionStorage.clear();
  },
};
</script>

<style scoped lang="scss">
.Login {
  height: 100%;
  .login_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    background: #fff;

    &.visitor {
      flex-direction: row-reverse;
    }

    .login_box_left {
      margin-right: 164px;
      background-color: #fff;
      .login_box_left_text {
        color: #83ba7b;
        font-size: 40px;
        white-space: nowrap;
      }
      .login_box_alert {
        width: 494px;
        text-align: center;
        color: #9a9ea7;
        font-size: 20px;
      }
    }
    .login_box_right {
      &.mr {
        margin-right: 103px;
      }
      img {
        width: 937px;
        height: 681px;
      }
    }
  }
  .login_box_form::v-deep {
    margin: 74px 0 85px;
    .ant-row {
      width: 502px;
      .ant-input {
        width: 494px;
      }
    }
  }
  .login_mode {
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    right: 50px;
    &:hover {
      color: #83ba7b;
    }
  }
}
</style>
