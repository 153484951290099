<template>
  <div class="User">
    <div class="user-header">
      <div class="user-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>访客列表</div>
        </div>
        <div class="creat-user" @click="cerateUser">新建访客</div>
      </div>
    </div>
    <div class="user-main">
      <a-table
        :columns="columns"
        :data-source="userList"
        :rowKey="(record) => record.id"
        :loading="loading"
        :pagination="pagination"
      >
        <!-- 性别筛选 -->
        <span slot="customSex">
          <a-select v-model="gender" @change="genderChange">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 男 </a-select-option>
            <a-select-option value="2"> 女 </a-select-option>
          </a-select>
        </span>

        <!-- 是否禁用 -->
        <span slot="customBan">
          <a-select v-model="ban" @change="banChange">
            <a-select-option value="全部"> 全部 </a-select-option>
            <a-select-option value="1"> 已禁用 </a-select-option>
            <a-select-option value="2"> 使用中 </a-select-option>
          </a-select>
        </span>
        <!--领导 -->
        <span slot="leader" slot-scope="leader">{{
          leader ? leader.name : "/"
        }}</span>

        <!-- 是否禁用 -->
        <span slot="isEnabled" slot-scope="enabled">
          <span>{{ enabled ? "使用中" : "已禁用" }}</span>
        </span>
        <!-- 操作 -->
        <div slot="action" class="handle" slot-scope="item, text, index">
          <div
            @click.stop="banUser(item, index)"
            :class="item.enabled ? '' : 'use'"
          >
            {{ item.enabled ? "禁用" : "恢复" }}
          </div>

          <div class="delete" @click.stop="deleteUser(item, text, index)">
            删除
          </div>

          <div class="edit" @click.stop="editPwd(item, text, index)">
            修改密码
          </div>
        </div>
      </a-table>
    </div>

    <!-- 新建用户弹框 -->
    <div ref="createRefModel" class="create-user-modal">
      <a-modal
        v-model="createVisible"
        :maskClosable="false"
        title="新建访客"
        @ok="handleOk"
        centered
        width="513px"
        :getContainer="() => $refs.createRefModel"
        @cancel="handleCancel"
      >
        <a-form-model
          :model="createUserForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          :colon="false"
        >
          <a-form-model-item label="姓名" prop="name">
            <a-input
              v-model="createUserForm.name"
              placeholder="请输入姓名"
              :maxLength="12"
            />
          </a-form-model-item>
          <a-form-model-item label="性别">
            <a-radio-group v-model="createUserForm.genderId">
              <a-radio value="1" :checked="createUserForm.genderId == 1">
                男
              </a-radio>
              <a-radio value="2"> 女 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="登录名" prop="username">
            <a-input
              v-model="createUserForm.username"
              placeholder="请输入6至12位字母或数字"
              :maxLength="20"
            />
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password">
            <a-input-password
              v-model="createUserForm.password"
              placeholder="请输入密码"
              :maxLength="20"
            />
          </a-form-model-item>
          <a-form-model-item label="所属集团" prop="gid">
            <a-select placeholder="请选择所属集团" v-model="createUserForm.gid">
              <a-select-option v-for="item in groupList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="职位">
            <a-input
              v-model="createUserForm.position"
              placeholder="请输入职位"
              :maxLength="20"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>

    <!-- 删除用户弹框 -->
    <my-modal
      :info="currentUserInfo"
      :visible="deleteVisible"
      :text="text"
      @ok="deleteHandleOk"
      @cancel="modalCancel"
    ></my-modal>

    <!-- 禁用弹框 -->
    <my-modal
      :info="currentUserInfo"
      :visible="banVisible"
      :text="text"
      @ok="banHandleOk"
      @cancel="modalCancel"
    ></my-modal>

    <!-- 提示弹框 -->
    <alert-modal
      :info="currentUserInfo"
      :visible="alertVisible"
      :text="text"
      @cancel="alertModalCancel"
      class="alert-modal"
      :isEdit="isEdit"
    ></alert-modal>

    <!-- 修改密码弹框 -->
    <pwd-modal
      :visible="pwdVisible"
      :info="currentUserInfo"
      @cancel="editModalCancel"
      :title="title"
      @ok="editHandleOk($event)"
      ref="pwdModal"
    ></pwd-modal>
  </div>
</template>

<script>
import { getUser, addUser, delUser, editUser } from "../common/api/user";
import { getGroup } from "../common/api/order";
import AlertModal from "../components/AlertModal.vue";
import MyModal from "../components/MyModal.vue";
import PwdModal from "../components/PwdModal.vue";
export default {
  name: "User",
  components: {
    AlertModal,
    MyModal,

    PwdModal,
  },

  data() {
    return {
      groupList: [],
      labelCol: { span: 9 },
      wrapperCol: { span: 20, offset: 1 },

      // 筛选性别
      gender: "性别筛选",

      // 是否禁用
      ban: "是否禁用",
      // 修改密码弹框标题
      title: "",
      // 修改密码弹框状态
      pwdVisible: false,

      // 提示弹框显示状态
      alertVisible: false,

      // 提示弹框文本内容
      text: "",
      // 新建用户表单数据
      labelCol: { span: 5 },
      wrapperCol: { span: 14, offset: 1 },
      // 新建用户请求参数
      createUserForm: {
        genderId: "1",
        name: "",
        password: "",
        gid: undefined,
        position: "",
        username: "",
        roleId: 5,
      },
      // 新建用户表单校验规则
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" },
          {
            validator: this.validateUsername,
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
        ],
        gid: [{ required: true, message: "请选择所属集团", trigger: "change" }],
      },

      // 加载状态
      loading: false,

      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      columns: [
        {
          title: "用户ID",
          dataIndex: "id",
          key: "id",
          align: "center",
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          align: "center",
        },

        {
          slots: { title: "customSex" },
          key: "gender",
          dataIndex: "gender",
          align: "center",
        },
        {
          title: "集团名称",
          dataIndex: "groupName",
          key: "groupName",
          align: "center",
        },
        {
          title: "登录名",
          dataIndex: "username",
          key: "username",
          align: "center",
        },

        {
          slots: { title: "customBan" },
          dataIndex: "enabled",
          key: "isEnabled",
          align: "center",
          scopedSlots: { customRender: "isEnabled" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],

      // 获取用户列表请求参数
      params: {
        page: 0,
        size: 10,
        roleId: 5,
      },
      // 用户列表
      userList: [],
      // 新建用户对话框状态
      createVisible: false,
      // 删除用户对话框状态
      deleteVisible: false,
      // 禁用对话框状态
      banVisible: false,

      // 直属领导列表
      leaderList: [],
      // 筛选领导列表
      screenLeaderList: [],
      // 选中用户信息对象
      currentUserInfo: {},
      // 选中的用户索引
      currentIndex: null,

      // 指派业务员列表
      appointList: [],
      // 是否修改密码弹框
      isEdit: false,
    };
  },
  methods: {
    // 校验登录名
    validateUsername(rule, value, callback) {
      var uPattern = /^[a-zA-Z0-9]{6,12}$/;

      if (uPattern.test(this.createUserForm.username)) {
        callback();
      } else {
        callback(new Error("请输入6至12位字母或数字"));
      }
    },
    // 获取门店集团
    async getGroupList() {
      const res = await getGroup({ page: 0, size: 999 });
      console.log(res, "集团");
      this.groupList = res.data.content;
    },

    // 性别筛选
    genderChange(e) {
      if (e == "") {
        this.gender = "性别筛选";
      }
      this.params.genderId = e;
      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },
    // 职位筛选
    jobChange(e) {
      if (e == "") {
        this.job = "职位筛选";
      }
      this.params.roleId = e;
      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },

    // 禁用筛选
    banChange(e) {
      if (e == "全部") {
        this.ban = "是否禁用";
        this.params.enable = "";
      } else if (e == "1") {
        this.params.enable = false;
      } else if (e == "2") {
        this.params.enable = true;
      }
      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },
    // 确认修改密码
    async editHandleOk(e) {
      // console.log(e);
      const params = {
        id: this.currentUserInfo.id,
        password: e.confirmPwd,
      };
      const res = await editUser(params);
      this.pwdVisible = false;
      this.$refs.pwdModal.$refs.ruleForm.resetFields();
      this.alertVisible = true;
    },
    // 修改密码
    editPwd(item, text, index) {
      this.isEdit = true;
      this.currentUserInfo = item;
      this.title = `修改用户“${this.currentUserInfo.name}”的密码`;
      this.pwdVisible = true;
    },
    // 关闭修改密码弹框
    editModalCancel() {
      this.pwdVisible = false;
    },

    // 关闭确认弹框
    modalCancel() {
      this.deleteVisible = false;
      this.banVisible = false;

      // if (this.modalType == "delete") {
      //   this.deleteVisible = false;
      // } else if (this.modalType == "ban") {
      //   this.banVisible = false;
      // }
    },
    // 确认禁用用户
    async banHandleOk() {
      const params = {
        id: this.currentUserInfo.id,
        enable: !this.currentUserInfo.enabled,
      };

      await editUser(params);

      this.userList[this.currentIndex].enabled =
        !this.userList[this.currentIndex].enabled;
      this.banVisible = false;
      this.alertVisible = true;
    },
    // 禁用用户
    banUser(item, index) {
      this.isEdit = false;
      // this.modalType = "ban";
      if (item.enabled) {
        this.text = "禁用";
      } else {
        this.text = "恢复";
      }

      this.banVisible = true;
      this.currentUserInfo = item;
      this.currentIndex = index;
    },
    // 关闭提示弹框
    alertModalCancel() {
      this.alertVisible = false;
    },
    // 删除用户
    deleteUser(item, text, index) {
      this.isEdit = false;
      // this.modalType = "delete";
      this.text = "删除";
      this.deleteVisible = true;
      this.currentUserInfo = item;
      this.currentIndex = index;
    },
    // 确认删除用户
    async deleteHandleOk() {
      await delUser({ id: this.currentUserInfo.id });
      if (this.userList.length === 1 && this.params.page !== 0) {
        this.params.page--;
        this.pagination.current--;
      }
      this.getUserList();
      this.deleteVisible = false;
      this.alertVisible = true;
      this.$store.commit("updateRefreshPath", "completeness");
    },

    // 新建用户
    cerateUser() {
      this.createVisible = true;
    },
    // 新建用户
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await addUser(this.createUserForm);
          console.log("新建用户：", res);

          this.createVisible = false;
          this.$message.success(res.msg);
          // this.createUserForm.genderId = "1";
          this.$refs.ruleForm.resetFields();
          // this.$store.commit("updateRefreshPath", "client");
          // this.$store.commit("updateRefreshPath", "order");
          // this.$store.commit("updateRefreshPath", "completeness");

          // 清空表单
          this.$refs.ruleForm.resetFields();
          this.pagination.current = 1;
          this.params.page = 0;
          this.getUserList();
        }
      });
    },
    // 关闭对话框
    handleCancel() {
      this.createUserForm.genderId = "1";
      // 清空表单
      this.$refs.ruleForm.resetFields();
    },

    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.params.page = 0;
      this.params.size = pageSize;
      this.getUserList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.params.page = page - 1;
      this.getUserList();
    },
    // 获取访客列表
    async getUserList() {
      // console.log("请求参数", this.params);
      this.loading = true;
      const res = await getUser(this.params);
      this.loading = false;
      console.log(res);

      this.userList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
  },
  created() {
    console.log(this.$router, 1111111111);
    this.getGroupList();
    this.getUserList();
  },
  activated() {
    let index = this.$store.state.refreshPath.findIndex((v) => {
      return v == "user";
    });
    if (index != -1) {
      console.log("触发了'activated'");
      this.getUserList();
      this.$store.commit("delRefreshPath", "user");
    }
  },
};
</script>

<style scoped lang="scss">
.User {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .user-header {
    margin-bottom: 32px;

    .user-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .creat-user {
        cursor: pointer;
        margin: 0 16px 0 30px;
        width: 88px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
      }
    }
  }
  .user-main {
    .ant-select::v-deep {
      // min-width: 130px;
      .ant-select-selection__rendered {
        display: flex;
        justify-content: center;
      }
    }
    .handle {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #9a9ea7;
      div {
        margin-left: 30px;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
      }
      .use {
        color: #1eb040;
      }

      .delete {
        color: #e93024;
      }
      .edit {
        color: #3298f1;
      }
    }
  }
  .create-user-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      padding: 20px 78px 0;
      .ant-form-item {
        margin-bottom: 20px;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
  .delete-user-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
  .alert-modal::v-deep {
    .ant-modal-footer .ant-btn:nth-child(1) {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
